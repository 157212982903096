.main-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 50vh;
}

.chart-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 50vh;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  font-size: 20px;
  color: red;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.select-container {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.heading {
  font-family: 'Merriweather', serif;
  text-align: center;
}

.notes {
  margin-bottom: 1rem;
  border: dashed 1px;
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 18px;
}


.heading.chart {
  padding: 10px;
}

.chart-container {
  display: flex;
  padding-top: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  .chart-container, .chart-loader-container {
    border: 1px solid #4c3c1b;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .heading.chart {
    text-decoration: underline;
  }
}

@media (min-width: 1025px) {
  .chart-container, .chart-loader-container {
    align-items: center;
    justify-content: center;
    border: none;
  }

  .heading.chart {
    border: 1px solid #4c3c1b;
  }

  .notes {
    margin-right: 8rem;
    margin-left: 8rem;
    font-size: 18px;
  }
}
